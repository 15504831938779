import React from "react";
import Grid from "@mui/material/Grid";
import { ThemeProvider } from "@mui/material/styles";
import { getStaticResourceSrc } from "../../../../../utils/getStaticResourceSrc";
import LeftSidebarArgusMobileHeader from "./LeftSidebarArgusMobileHeader";
import LeftSidebarDisclaimer from "./LeftSidebarDisclaimer";
import { useLeftSidebarTheme } from "./LeftSidebarTheme";
export const LeftSidebar = () => {
    const { theme, parentTheme } = useLeftSidebarTheme();
    return (<ThemeProvider theme={theme}>
      <Grid item sx={{
            width: "280px",
            background: (theme) => theme.palette.background.default,
            borderTopLeftRadius: (theme) => theme.shape.borderRadius,
            borderBottomLeftRadius: (theme) => theme.shape.borderRadius,
            padding: "24px !important",
            minHeight: "514px",
            height: "auto",
            display: "flex",
            flexDirection: "column",
        }}>
        <Grid container flexGrow={0}>
          <img src={getStaticResourceSrc("images/argus-logo-light.svg")} style={{
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: theme.spacing(2),
            height: 36,
            display: "block",
        }}/>
          <LeftSidebarArgusMobileHeader />
        </Grid>
        <Grid container flexGrow={0} mt="auto">
          <LeftSidebarDisclaimer parentTheme={parentTheme}/>
        </Grid>
      </Grid>
    </ThemeProvider>);
};
