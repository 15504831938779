import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { FormattedMessage } from "@mnemonic/intl";
import { getTargetMeta } from "@mnemonic/pure-utils";
import { useFetchAuthenticationMethodsForUserQuery } from "../../Redux/Service/methodsApi";
import { commonMessages } from "../../commonMessages";
import { createAuthenticationListItems, setSessionStorage, userHasUnConfiguredPreferredAuthMethods } from "./utils";
const initialFlowState = { kind: "verifyUser" };
export const useVerifyUsernamePassword = () => {
    const [searchParams] = useSearchParams();
    const userName = searchParams.get("userName") || null;
    const enrollmentCode = searchParams.get("enrollmentCode") || null;
    const [enrollFlowState, setEnrollFlowState] = useState({
        step: initialFlowState,
        userName: userName,
        enrollmentCode: enrollmentCode,
    });
    const { metaInfo } = getTargetMeta();
    const domain = metaInfo.theme === "mnemonic" ? "MNEMONIC" : "NFCERT";
    const autoSubmit = Boolean(userName) && Boolean(enrollmentCode);
    const { data, isLoading } = useFetchAuthenticationMethodsForUserQuery({
        userId: enrollFlowState.userId,
        metaOpts: { sessionKey: enrollFlowState?.authorization?.sessionKey },
    });
    const availableAuthMethods = data?.data || [];
    const unConfiguredPreferredAuthMethods = userHasUnConfiguredPreferredAuthMethods(availableAuthMethods);
    const steps = [
        { key: "verifyUser", label: <FormattedMessage {...commonMessages.EnrollmentCode}/> },
        { key: "verifySmsCode", label: <FormattedMessage {...commonMessages.VerificationCode}/> },
        { key: "definePassword", label: <FormattedMessage {...commonMessages.SetPassword}/> },
    ];
    const activeStep = steps.findIndex((step) => step.key === enrollFlowState.step.kind);
    return {
        activeStep,
        authSetup: {
            listItems: createAuthenticationListItems(availableAuthMethods, enrollFlowState?.authorization?.authorization),
            unConfiguredPreferredAuthMethods,
            handleClick: setSessionStorage,
        },
        autoSubmit,
        domain,
        enrollFlowState,
        isLoading,
        setEnrollFlowState,
        steps,
    };
};
