import React from "react";
import Grid from "@mui/material/Grid";
import { Section } from "@mnemonic/mui5/src/Section";
import * as ArgusUtils from "@mnemonic/pure-utils/ArgusUtils";
import { LeftSidebar } from "./LeftSidebar/LeftSidebar";
export const VerifyBox = (props) => {
    const { metaInfo } = ArgusUtils.getTargetMeta();
    //Hiding sidebar if domain != mnemonic as it's mostly used to advertise Argus Mobile.
    const displayLeftSidebar = metaInfo.theme === "mnemonic";
    return (<Section loading={props.loading} sx={(theme) => ({
            m: "auto",
            minHeight: "514px",
            marginTop: theme.spacing(16),
            width: displayLeftSidebar ? "776px" : "496px",
            // If on dark-mode, remove box-shadow and add outline.
            ...theme.applyStyles("dark", { boxShadow: "none", outline: `1px solid ${theme.palette.divider}` }),
        })}>
      <Section.Content sx={{ pt: 2, pr: 0, pl: 2 }}>
        <Grid container display="flex" spacing={2}>
          {displayLeftSidebar && <LeftSidebar />}
          <Grid item width="496px" sx={({ spacing }) => ({ padding: `${spacing(4)} !important` })}>
            {props.children}
          </Grid>
        </Grid>
      </Section.Content>
    </Section>);
};
